import { styles } from "./Teaser.styles";
import { ITeaserProps, IOptionButton } from "./Teaser.types";
import { renderTeaserTemplate } from "./Teaser.template";
import { ICONS } from "./icons";

declare global {
  interface Window {
    FurtherSiteManager: any;
  }
}

export function renderServerSide(props: ITeaserProps) {
  return `
  <template shadowrootmode="open">
      <style>
        ${styles}
      </style>

      <style>
        ${props.customCss}
      </style>

      ${renderTeaserTemplate(props)}
  </template>
  `;
}

export function renderClientSide(props: ITeaserProps) {
  return `
      <style>
        ${styles}
      </style>

      <style>
        ${props.customCss}
      </style>

      ${renderTeaserTemplate(props)}
  `;
}

export function registerTeaserWebComponent() {
  if (typeof window !== "undefined" && typeof HTMLElement !== "undefined") {
    class TeaserElement extends HTMLElement {
      state: ITeaserProps;
      resizeObserver: ResizeObserver;

      constructor() {
        super();

        this.state = {
          vsaInstance: "",
          title: "",
          subtitle: "",
          options: [],
          theme: {},
          customCss: "",
        };

        this.render();
      }

      static register() {
        if (!window.customElements.get("further-teaser")) {
          window.customElements.define("further-teaser", TeaserElement);
        }
      }

      get vsaInstance() {
        return this.state?.vsaInstance || "";
      }

      set vsaInstance(vsaInstance: string) {
        this.state!.vsaInstance = vsaInstance;
        this.render();
      }

      get title() {
        return this.state?.title || "";
      }

      set title(title: string) {
        this.state!.title = title;
        this.render();
      }

      get subtitle() {
        return this.state?.subtitle || "";
      }

      set subtitle(subtitle: string) {
        this.state!.subtitle = subtitle;
        this.render();
      }

      get options() {
        return this.state?.options || [];
      }

      set options(options: IOptionButton[]) {
        this.state!.options = options;
        this.render();
      }

      get theme() {
        return this.state?.theme || {};
      }

      set theme(theme: ITeaserProps["theme"]) {
        this.state!.theme = theme;
        this.render();
      }

      get customCss() {
        return this.state?.customCss || "";
      }

      set customCss(customCss: string) {
        this.state!.customCss = customCss;
        this.render();
      }

      connectedCallback() {
        this.render();
      }

      disconnectedCallback() {
        this.resizeObserver?.disconnect();
      }

      static get observedAttributes() {
        return ["vsa-instance", "title", "subtitle", "options", "theme", "custom-css"];
      }

      attributeChangedCallback(
        name: "vsa-instance" | "title" | "subtitle" | "options" | "theme" | "custom-css",
        _: any,
        newValue: any
      ) {
        if (!this.state) return;

        const key = {
          "vsa-instance": "vsaInstance",
          title: "title",
          subtitle: "subtitle",
          options: "options",
          theme: "theme",
          "custom-css": "customCss",
        }[name];

        try {
          this.state[key] = JSON.parse(newValue);
        } catch (e) {
          this.state[key] = newValue;
        }
        this.render();
      }

      render() {
        if (!this.shadowRoot) {
          this.innerHTML = "";
          this.attachShadow({ mode: "open" });
        }

        if (this.resizeObserver) {
          this.resizeObserver.disconnect();
        }

        const r = this.shadowRoot!;

        r.innerHTML = renderClientSide(this.state);

        // apply the attributes to the Shadow DOM content
        const title = r.querySelector(".Header h1");
        if (title) {
          title.innerHTML = this.state?.title || "";
        }

        const subtitle = r.querySelector(".Header h2");
        if (subtitle) {
          subtitle.innerHTML = this.state?.subtitle || "";
        }

        const form = r.querySelector("form");
        if (!form) return;

        form.addEventListener("submit", async (e) => {
          e.preventDefault();
          const formData = new FormData(form);
          const targetModule = formData.get("segment_1") as string;
          if (targetModule) {
            const optionButtons = form.querySelectorAll(".option-button");
            optionButtons.forEach((button) => {
              button.classList.add("disabled");
            });
            await this.openEmbeddedVSA(targetModule);
            optionButtons.forEach((button) => {
              button.classList.remove("disabled", "loading");
            });
          }
        });

        const options = r.querySelector(".Options");
        if (!options) return;

        options.innerHTML = "";

        this.state?.options.forEach((option) => {
          const optionButton = this.renderTeaserOption(option);

          optionButton.addEventListener("change", (e) => {
            const clikedButton = e.currentTarget as HTMLLabelElement;
            clikedButton.classList.add("loading");
            form.dispatchEvent(
              new Event("submit", {
                bubbles: true,
                cancelable: true,
              })
            );
            (e.target as HTMLInputElement).checked = false;
          });
          options?.appendChild(optionButton);
        });

        this.initResizeObserver();
      }

      renderTeaserOption(option: IOptionButton) {
        const optionButton = document.createElement("label");
        optionButton.classList.add("option-button");
        if (option.primary) {
          optionButton.classList.add("primary");
        }
        let icon = option.icon && ICONS[option.icon] ? ICONS[option.icon] : "";
        if (icon) {
          optionButton.classList.add("with-icon");
        }

        optionButton.innerHTML = /*HTML*/ `
          <input type="radio" name="segment_1" value="${option.action}" />
          ${icon ? '<span class="icon sentinel"></span>' : ""}
          <span class="label-wrapper">
            <slot name="btn-text">${option.label}</slot>
          </span>
          ${icon}
      `;

        return optionButton;
      }

      async initResizeObserver() {
        if (!window.ResizeObserver) {
          const { install } = await import("resize-observer");
          install();
        }

        const teaserRoot = this.shadowRoot?.querySelector(".Teaser");

        this.resizeObserver = new ResizeObserver((entries) => {
          entries.forEach((entry) => {
            if (entry?.target === this.shadowRoot?.host) {
              const width = entry.contentRect.width;
              const isHorizontal = width > 670;
              if (isHorizontal) {
                teaserRoot?.classList.remove("vertical");
                teaserRoot?.classList.add("horizontal");
              } else {
                teaserRoot?.classList.remove("horizontal");
                teaserRoot?.classList.add("vertical");
              }

              const wrapSegments = isHorizontal && teaserRoot?.clientWidth! < 1000;
              if (wrapSegments) {
                teaserRoot?.classList.add("wrap-segments");
              } else {
                teaserRoot?.classList.remove("wrap-segments");
              }
            }
          });
        });

        this.resizeObserver.observe(this.shadowRoot!.host);
      }

      async loadSiteManager() {
        // Would be nice to do const SiteManager = await import("https://js.talkfurther.com/talkfurther_init.min.js");
        // but that's not supported yet on the Site Manager side of things.
        return new Promise((resolve, reject) => {
          var script = document.createElement("script");
          script.type = "text/javascript";
          script.src = "https://js.talkfurther.com/talkfurther_init.min.js";
          script.async = true;
          document.body.appendChild(script);
          let attempts = 0;
          const interval = setInterval(() => {
            if (window.FurtherSiteManager) {
              clearInterval(interval);
              resolve(true);
            } else {
              attempts++;
              if (attempts > 1000) {
                clearInterval(interval);
                reject("Site Manager failed to load");
              }
            }
          }, 10);
        });
      }

      async openEmbeddedVSA(targetModule: string) {
        try {
          if (!window.FurtherSiteManager) {
            console.log("Site Manager not loaded, loading...");
            await this.loadSiteManager();
          }

          await window.FurtherSiteManager.openEmbeddedVSA({
            vsaInstance: this.vsaInstance,
            targetModule,
          });
        } catch (e) {
          console.error(e);
        }
      }
    }

    TeaserElement.register();
  }
}
