export const styles = /*css*/ `
* {
  box-sizing: border-box;
}

:host {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  container-type: inline-size;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.animate__fadeIn {
  animation: fadeIn 0.6s;
  animation-fill-mode: forwards;
}

.loading::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: var(--teaserBorderRadius);
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0.5;
}

.loading::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin: -10px 0 0 -10px; /* Center the spinner */
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  z-index: 2;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Teaser {
  position: relative;
  display: flex;
  align-items: center;
  overflow: visible;
  font-size: var(--fontSize);
  width: 100%;
  height: 100%;
  min-width: var(--teaserMinWidth);
  min-height: var(--teaserMinHeight);
  max-width: var(--teaserMaxWidth);
  max-height: var(--teaserMaxHeight);
  padding: var(--teaserPadding);
  margin: var(--teaserMargin);
  font-family: var(--fontFamily);
  box-shadow: var(--teaserBoxShadow);
  border-radius: var(--teaserBorderRadius);
  background-color: var(--teaserBackground);
}
.Teaser .Options {
  flex-wrap: nowrap;
}
.Teaser .segments {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
.Teaser.horizontal .segments {
  flex-direction: row;
  justify-content: space-between;
}
.Teaser.horizontal .Options {
  flex-direction: row;
  justify-content: var(--justifyTeaserButtons, flex-end);
  margin-left: var(--teaserSegmentsGap);
}
.Teaser.horizontal .Header h1,
.Teaser.horizontal .Header h2,
.Teaser.horizontal .Header p {
  text-align: left;
  margin-right: 30px;
}

@container (width >= 670px) {
  .Teaser .segments {
    flex-direction: row;
    justify-content: space-between;
  }
  .Teaser .Options {
    flex-direction: row;
    justify-content: var(--justifyTeaserButtons, flex-end);
    margin-left: var(--teaserSegmentsGap);
  }
  .Teaser .Header h1,
  .Teaser .Header h2,
  .Teaser .Header p {
    text-align: left;
    margin-right: 30px;
  }
}


.Teaser.vertical .segments {
  flex-direction: column;
  justify-content: center;
}
.Teaser.vertical .segments .Options {
  flex-direction: column;
  margin-top: var(--teaserSegmentsGap);
}
.Teaser.vertical .Header h1,
.Teaser.vertical .Header h2,
.Teaser.vertical .Header p {
  text-align: center;
}

@container (width < 670px) {
  .Teaser .segments {
    flex-direction: column;
    justify-content: center;
  }
  .Teaser .segments .Options {
    flex-direction: column;
    margin-top: var(--teaserSegmentsGap);
  }
  .Teaser .Header h1,
  .Teaser .Header h2,
  .Teaser .Header p {
    text-align: center;
  }
}



.Teaser.horizontal.wrap-segments .segments {
  flex-wrap: wrap;
}
.Teaser.horizontal.wrap-segments .option-button,
.Teaser.horizontal.wrap-segments .option-button.primary {
    font-size: calc(var(--buttonLabelFontSize, var(--fontSize)) - 4px);
  padding-left: 24px;
  padding-right: 24px;
}

.Teaser.horizontal.wrap-segments .Options {
  margin-left: 0;
  margin-top: var(--teaserSegmentsGap);
}

@container (width > 670px) and (width < 1000px) {
  .Teaser .segments {
    flex-wrap: wrap;
  }
  .Teaser .option-button,
  .Teaser .option-button.primary {
    // font-size: 14px;
    font-size: calc(var(--buttonLabelFontSize, var(--fontSize)) - 4px);
    padding-left: 24px;
    padding-right: 24px;
  }

  .Teaser .Options {
    margin-left: 0;
    margin-top: var(--teaserSegmentsGap);
  }
}


.Teaser.transparent {
  background-color: transparent;
  box-shadow: none;
}
.Teaser .spinner .bar {
  background: rgba(200, 200, 200, 0.7);
}
.Teaser .Header h1 {
  display: var(--headerTitleDisplay, block);
}
.Teaser .Header h2,
.Teaser .Header p {
  display: var(--headerSubtitleDisplay, block);
}

.Header {
  font-family: var(--fontFamily);
}
.Header h1,
.Header h2 {
  text-align: center;
  margin: 10px 0;
  line-height: normal;
  min-width: 225px;
}
.Header h1 {
  font-family: var(--headerTitleFontFamily, var(--fontFamily));
  font-size: var(--headerTitleFontSize, 26px);
  color: var(--headerTitleColor, var(--brandColor));
  font-weight: var(--headerTitleFontWeight, var(--fontWeight));
  line-height: var(--headerTitleLineHeight);
}
.Header h2,
.Header p {
  font-family: var(--headerSubtitleFontFamily, var(--fontFamily));
  font-size: var(--headerSubtitleFontSize, var(--fontSize));
  color: var(--headerSubtitleColor, var(--color));
  font-weight: var(--headerSubtitleFontWeight, var(--fontWeight));
  line-height: var(--headerSubtitleLineHeight);
}
.Header p {
  font-weight: normal;
  margin: 8px 0;
  vertical-align: middle;
  text-align: center;
  white-space: pre-wrap;
}
.Header img[alt="interested_people"] {
  width: 95px;
  margin-top: 10px;
}

.Options {
  display: flex;
  flex-direction: column;
  align-items: var(--alignTeaserButtons, center);
  justify-content: var(--justifyTeaserButtons, center);
  width: 100%;
  gap: 10px;
  flex-wrap: wrap;
}
@supports not (aspect-ratio: 1) {
  .Options {
    margin: -5px;
  }
}
.Options .option-button {
  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 16px 40px;
  height: var(--buttonHeight, 60px);
  min-height: 48px;
  width: 100%;
  max-width: 400px;
  background-color: white;
  box-shadow: var(--buttonShadow);
  border: var(--buttonsBorder, 1px solid #eaeaea);
  border-radius: var(--buttonsBorderRadius);
  text-align: center;
  font-size: var(--buttonLabelFontSize, var(--fontSize));
  font-family: var(--fontFamily);
  font-weight: normal;
  color: var(--color);
  white-space: nowrap;
  user-select: none;
  text-decoration: none;
  cursor: pointer;
}
.Options .option-button.disabled {
  pointer-events: none;
  position: relative;
  overflow: hidden;
  filter: brightness(80%);
}



@supports not (aspect-ratio: 1) {
  .Options .option-button {
    margin: 5px;
  }
}
.Options .option-button:hover {
  filter: brightness(95%);
}
.Options .option-button.checked {
  border: solid 1px #3b3960;
  background-color: rgba(59, 57, 96, 0.05);
}
.Options .option-button.selected span {
  z-index: 2;
}
.Options .option-button.selected:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 1;
}
.Options .option-button input[type="radio"],
.Options .option-button input[type="checkbox"] {
  display: none;
}
.Options .option-button.primary {
  background-color: var(--brandColor);
  color: var(--buttonColor);
}
.Options .option-button.primary .spinner .bar {
  background: var(--buttonColor);
}
.Options .option-button.primary svg path[fill-rule="nonzero"] {
  fill: var(--buttonColor);
}
.Options .option-button.with-sub-label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 24px;
}
.Options .option-button.with-sub-label .label-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.2;
  z-index: 3;
}
.Options .option-button.with-sub-label .label-wrapper > span:first-child {
  font-size: var(--buttonLabelFontSize, var(--fontSize));
  font-weight: 600;
}
.Options .option-button.with-sub-label .label-wrapper > span:last-child {
  font-size: var(--buttonSublabelFontSize, 12px);
  color: var(--buttonColor);
  font-family: var(--fontFamily);
  font-weight: 500;
}
.Options .option-button.with-icon {
  justify-content: space-between;
  gap: 1ch;
}
.Options .option-button .icon {
  width: 24px;
  height: 24px;
  min-height: 15px;
  min-width: 15px;
}
.Options .option-button.flash {
  animation: fadeOut 0.5s ease-in-out;
}
@media screen and (max-width: 389px) {
  .Options .option-button .icon,
  .Options .option-button .icon .sentinel {
    display: none;
  }
  .Options .option-button.with-icon {
    justify-content: center;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
`;
